<template>
  <div id="particle-container">
    <vue-particles id="tsparticles" :particlesInit="particlesInit" :options="config" />
  </div>
</template>

<script setup>
import { loadSlim } from 'tsparticles-slim'

const particlesInit = async (engine) => {
  await loadSlim(engine)
}

const config = {
  fpsLimit: 120,
  interactivity: {
    events: {
      onClick: {
        enable: false,
        mode: 'push'
      },
      onHover: {
        enable: true,
        mode: 'bubble'
      },
      resize: true
    },
    modes: {
      bubble: {
        distance: 150,
        duration: 10,
        opacity: 1,
        size: 50
      }
    }
  },
  particles: {
    color: {
      value: ['#FF3939', '#F842DB', '#7042F8', '#4642F8', '#0275FF', '#42C1F8'], // Array de cores para gradiente
      animation: {
        enable: false,
        speed: 2,
        sync: true
      }
    },
    move: {
      direction: 'top', // Define a direção das partículas
      enable: true, // Habilita o movimento das partículas
      outModes: {
        default: 'bounce', // As partículas irão "quicar" quando atingirem os limites
        top: 'bounce', // As partículas saem pela parte superior
        bottom: 'bounce' // As partículas são destruídas quando atingem o fundo
      },
      random: false, // Define se a velocidade do movimento é aleatória
      speed: 2, // Define a velocidade das partículas
      straight: false // As partículas não se movem em linha reta
    },
    number: {
      density: {
        enable: true,
        area: 200
      },
      value: 30
    },
    opacity: {
      value: 1
    },
    shape: {
      type: 'circle' // Forma das partículas
    },
    size: {
      value: 20 // Ajuste o valor do tamanho conforme necessário
    }
  },
  detectRetina: true
}
</script>
