<template>
  <div id="perfil">
    <ModalAlterarSenha/>
    <ModalEditarPerfil/>
    <SectionTopo/>
    <SectionCriar/>
    <Footer/>
  </div>
</template>

<script setup>
import ModalAlterarSenha from './modals/ModalAlterarSenha.vue'
import ModalEditarPerfil from './modals/ModalEditarPerfil.vue'
import SectionTopo from './partials/SectionTopo.vue'
import SectionCriar from './partials/SectionCriar.vue'
import Footer from '../../global/footer/Footer.vue'

import { onMounted } from 'vue'
import { useStorePerfil } from '../../../stores/index.js'
const storePerfil = useStorePerfil()

function carregarPerfil () {
  storePerfil.receberPerfilAdministrador()
}

onMounted(() => {
  carregarPerfil()
})

</script>

<style scoped>
</style>

