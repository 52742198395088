import { defineStore } from 'pinia'

export const useStoreCropImagem = defineStore('storeCropImagem', {
  state: () => {
    return {
      cropConfig: {
        stencilProps: {
          handlers: {},
          movable: false,
          resizable: true,
          aspectRatio: 1.8
        },
        resizeImage: {
          adjustStencil: false
        },
        tipoRecorte: 'retangulo'
      },
      loading: false,
      modalUploadFotoAberto: false,
      cropAtivado: false,
      tamanhoMaximo: 500,
      imagem: {
        src: '',
        type: ''
      },
      imagemBackup: {
        src: '',
        type: ''
      },
      callback: ''
    }
  }
})
