<template>
  <section class="dados">
    <h2>{{ contarUsuariosLiberados }} usuários com acesso liberado</h2>
    <div class="quadros liberado">
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-test.svg" />
        <h3>{{ contarUsuariosTest }} Test</h3>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-silver.svg" />
        <h3>{{ contarUsuariosSilver }} Silver</h3>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-gold.svg" />
        <h3>{{ contarUsuariosGold }} Gold</h3>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-diamond.svg" />
        <h3>{{ contarUsuariosDiamond }} Diamond</h3>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-unlimited.svg" />
        <h3>{{ contarUsuariosUnlimited }} Unlimited</h3>
      </div>
    </div>
    <h2>{{ contarUsuariosBloqueados }} usuários com acesso bloqueado</h2>
    <div class="quadros bloqueado">
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-test.svg" />
        <h3>{{ contarUsuariosTestBloqueado }} Test</h3>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-silver.svg" />
        <h3>{{ contarUsuariosSilverBloqueado }} Silver</h3>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-gold.svg" />
        <h3>{{ contarUsuariosGoldBloqueado }} Gold</h3>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-diamond.svg" />
        <h3>{{ contarUsuariosDiamondBloqueado }} Diamond</h3>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-unlimited.svg" />
        <h3>{{ contarUsuariosUnlimitedBloqueado }} Unlimited</h3>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreUsuarios } from '../../../../stores/index.js'

const storeUsuarios = useStoreUsuarios()

const contarUsuariosLiberados = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.ativo).length
})

const contarUsuariosTest = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'test' && usuario.assinatura?.ativo).length
})

const contarUsuariosSilver = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'silver' && usuario.assinatura?.ativo).length
})

const contarUsuariosGold = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'gold' && usuario.assinatura?.ativo).length
})

const contarUsuariosDiamond = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'diamond' && usuario.assinatura?.ativo).length
})

const contarUsuariosUnlimited = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'unlimited' && usuario.assinatura?.ativo).length
})

const contarUsuariosBloqueados = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => !usuario.assinatura?.ativo).length
})

const contarUsuariosTestBloqueado = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'test' && !usuario.assinatura?.ativo).length
})

const contarUsuariosSilverBloqueado = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'silver' && !usuario.assinatura?.ativo).length
})

const contarUsuariosGoldBloqueado = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'gold' && !usuario.assinatura?.ativo).length
})

const contarUsuariosDiamondBloqueado = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'diamond' && !usuario.assinatura?.ativo).length
})

const contarUsuariosUnlimitedBloqueado = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'unlimited' && !usuario.assinatura?.ativo).length
})
</script>

<style scoped>
section.dados {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 20px 0 290px;
}

h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 20px 0;
}

.quadros {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid var(--cor-cinza-3);
  padding: 30px;
  border-radius: 10px;
  margin: 0 0 40px 0;
}

.quadros.liberado {
  border: 1px solid var(--cor-verde);
}

.quadros.bloqueado {
  border: 1px solid var(--cor-vermelho);
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(20% - 15px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 40px 20px;
}

.quadro img {
  width: 100%;
  max-width: 40px;
}

.quadro h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 15px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.dados {
    padding: 20px;
  }

  .quadros {
    justify-content: flex-start;
    padding: 20px;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .quadros::-webkit-scrollbar {
    display: none;
  }

  h2 {
    font-size: var(--f2);
  }

  .quadro {
    width: 190px;
    min-width: 190px;
    padding: 30px 15px;
    margin: 0 20px 0 0;
  }

  .quadro img {
    max-width: 25px;
  }
}
</style>
