<template>
  <section class="topo">
    <div class="foto" :style="`background-image: url(${storePerfil.usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'})`"></div>
    <div class="texto">
      <h3>{{ storePerfil.usuario?.nome }}</h3>
      <p>{{ storePerfil.usuario?.email }}</p>
    </div>
  </section>
</template>

<script setup>
import { useStorePerfil } from '../../../../stores/index.js'

const storePerfil = useStorePerfil()
</script>

<style scoped>
  section.topo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 80px 20px 0 290px;
  }
  .foto {
    width: 135px;
    height: 135px;
    min-width: 135px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }
  .texto {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .texto h3 {
    font-family: var(--bold);
    font-size: var(--f3);
    color: var(--cor-branco);
    line-height: 1.5;
    margin: 20px 0 6px 0;
  }
  .texto p {
    font-family: var(--light);
    font-size: var(--f2);
    color: var(--cor-cinza-5);
    line-height: 1.5;
  }
  /* Responsivo */
  @media screen and (max-width: 1000px) {
    section.topo {
      flex-direction: column;
      padding: 130px 20px 0 20px;
    }
    .plano {
      width: 100%;
      padding: 15px;
    }
    .perfil .foto {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
    .plano .info {
      padding: 0 0 0 10px;
    }
    .plano .info .texto {
      width: 100%;
      padding: 0 2.5px 10px 2.5px;
    }
    .plano .info .texto p {
      font-size: var(--f1);
    }
    .plano .info .linha span {
      margin: 0 2.5px;
    }
    .perfil {
      display: none;
    }
  }
</style>
