<template>
  <div id="entrar">
    <SectionEntrar/>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { helperToken } from '../../../helpers/index.js'
import SectionEntrar from './partials/SectionEntrar.vue'

function bloquearRotaSeLogado() {
  if (!helperToken.detectarTokenOk()) return
  window.location.assign('/usuarios')
}

onMounted(() => {
  bloquearRotaSeLogado()
})

</script>

<style scoped>
#entrar {
  position: relative;
}
</style>

