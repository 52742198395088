<template>
  <nav :class="{ ativo: state.menuAberto }">
    <div class="linha"></div>
    <div class="titulo">
      <Svgs nome="logo-unic" />
      <h3>
        <span>{{ storePerfil.usuario?.nome }}</span>
        , essa é a área administrativa da UnicPages
      </h3>
      <button class="menu" :class="{ ativo: state.menuAberto }" @click="state.menuAberto = !state.menuAberto" ref="menu">
        <span class="um"></span>
        <span class="dois"></span>
      </button>
    </div>
    <div class="opcoes">
      <a @click="router.push('/usuarios')">
        <Svgs nome="usuario" />
        <p>Usuários</p>
      </a>
      <a @click="router.push('/sites')">
        <Svgs nome="cursor" />
        <p>Sites</p>
      </a>
      <a @click="router.push('/dados')">
        <Svgs nome="lupa" />
        <p>Dados</p>
      </a>
    </div>
    <a class="perfil" @click="router.push('/perfil')">
      <div class="foto" :style="`background-image: url(${storePerfil.usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'})`"></div>
      <div class="texto">
        <h3>{{ storePerfil.usuario?.nome }}</h3>
        <div class="assinatura">
          <img src="https://arquivos.unicpages.com/imagens/app/planos/admin.png" />
          <p>Administrador</p>
        </div>
      </div>
    </a>
  </nav>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStorePerfil } from '../../../stores/index.js'
import { helperClicouFora } from '../../../helpers/index.js'
import Svgs from '../svgs/Svgs.vue'

const router = useRouter()
const menu = ref()
const storePerfil = useStorePerfil()
const state = reactive({
  menuAberto: false
})

helperClicouFora.clicouFora([{ alvo: menu, retornoChamada: () => (state.menuAberto = false) }])
</script>

<style scoped>
nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  top: 20px;
  left: 20px;
  min-height: calc(100dvh - 40px);
  max-height: calc(100dvh - 40px);
  height: calc(100dvh - 40px);
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  z-index: 10;
}

nav .linha {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, var(--degrade));
  border-radius: 10px 10px 0 0;
  background-size: 200% 200%;
  background-position: center;
  animation: degradeHorizontal 30s ease-in-out infinite;
}

nav .titulo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 35px 30px 30px 30px;
  border-bottom: 2px solid var(--cor-cinza-3);
}

nav .titulo svg {
  width: 120px;
  min-width: 120px;
  fill: var(--cor-branco);
}

nav .titulo h3 {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 15px 0 5px 0;
}

nav .titulo button.menu {
  display: none;
  position: absolute;
  right: 20px;
  width: 35px;
  height: 35px;
  background-color: transparent;
}

nav .titulo button.menu span {
  position: absolute;
  background-color: var(--cor-branco);
  border-radius: 10px;
  height: 3px;
  transition: all 0.5s;
}

nav .titulo button.menu:hover span {
  background-color: var(--cor-cinza-5);
}

nav .titulo button.menu span.um {
  top: 10px;
  right: 0;
  width: 25px;
}

nav .titulo button.menu span.dois {
  bottom: 10px;
  right: 0;
  width: 35px;
}

nav.ativo .titulo button.menu span {
  background-color: var(--cor-vermelho);
}

nav.ativo .titulo button.menu span.um {
  transform: rotate(45deg);
  width: 35px;
  top: 16px;
}

nav.ativo .titulo button.menu span.dois {
  transform: rotate(-45deg);
  bottom: 16px;
}

nav .opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 15px 0 0 0;
  margin-bottom: auto;
}

nav .opcoes a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  width: 100%;
  padding: 15px 30px;
  position: relative;
}
nav .opcoes a.breve {
  pointer-events: none;
}
nav .opcoes a.breve svg {
  fill: var(--cor-cinza-4);
}
nav .opcoes a.breve p {
  opacity: 0.6;
}
nav .opcoes a svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
  z-index: 1;
}
nav .opcoes a p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  z-index: 1;
  transition: all 0.3s;
}
nav .opcoes a:hover p {
  color: var(--cor-branco);
}
nav .opcoes a.ativo p {
  color: var(--cor-cinza-5);
}
nav .opcoes a span {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-azul);
  margin: 0 0 0 5px;
}
nav .opcoes a.ativo span {
  opacity: 1;
  visibility: visible;
}

nav .opcoes a:hover span {
  opacity: 1;
  visibility: visible;
}

nav a.perfil {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  border: 2px solid var(--cor-cinza-3);
  width: 100%;
  transition: all 0.3s;
}

nav a.perfil:hover {
  background-color: var(--cor-cinza-2);
}

nav a.perfil .foto {
  width: 25px;
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

nav a.perfil .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}
nav a.perfil .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}
nav a.perfil .texto .assinatura {
  margin: 5px 0 0 0;
}
nav a.perfil .texto .assinatura p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  text-transform: capitalize;
}
nav a.perfil .texto .assinatura {
  display: flex;
  align-items: center;
}
nav a.perfil .texto .assinatura img {
  width: 12px;
  max-width: 12px;
  margin: 0 5px 0 0;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  nav {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 20px;
    left: 20px;
    min-height: 60px;
    max-height: 60px;
    height: 70px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    border-top: 2px solid var(--cor-cinza-1);
    border-bottom: 2px solid var(--cor-cinza-1);
  }

  nav .linha {
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: linear-gradient(0deg, var(--degrade));
    border-radius: 10px 0 0 10px;
    animation: degradeVertical 30s ease-in-out infinite;
  }

  nav .titulo {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px 0 25px;
    border-bottom: none;
  }

  nav .titulo svg {
    width: 110px;
    min-width: 110px;
  }

  nav .titulo h3 {
    display: none;
  }

  nav .titulo button.menu {
    display: flex;
  }

  nav .opcoes {
    width: calc(100% - 40px);
    padding: 10px 0 10px 0;
    margin-bottom: 0;
    position: fixed;
    top: 150px;
    left: 0px;
    background-color: var(--cor-cinza-2);
    border-radius: 0 0 10px 10px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s;
  }

  nav.ativo .opcoes {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    left: 20px;
  }

  nav .opcoes a {
    padding: 20px;
  }

  nav a.perfil {
    padding: 0 20px;
    width: calc(100% - 40px);
    height: 70px;
    min-height: 70px;
    transition: all 0.3s;
    position: fixed;
    top: 85px;
    left: 40px;
    border-radius: 10px 10px 0 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s;
  }

  nav.ativo a.perfil {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    left: 20px;
  }

  nav a.perfil .foto {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }
}
</style>
