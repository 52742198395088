<template>
  <section class="criar">
    <button @click="abrirModalAlterarSenha">
      <div class="texto">
        <h3>Alterar senha</h3>
        <p>Edite sua senha aqui</p>
      </div>
      <div class="icone">
        <Svgs nome="cadeado"/>
      </div>
    </button>
    <button @click="abrirModalEditarPerfil">
      <div class="texto">
        <h3>Editar perfil</h3>
        <p>Altere suas informações</p>
      </div>
      <div class="icone">
        <Svgs nome="usuario"/>
      </div>
    </button>
    <button @click="logout">
      <div class="texto">
        <h3>Sair da Conta</h3>
        <p>Deslogue de sua conta</p>
      </div>
      <div class="icone">
        <Svgs nome="sair"/>
      </div>
    </button>
  </section>
</template>

<script setup>
import { useStorePerfil } from '../../../../stores/index.js'
import Svgs from '../../../global/svgs/Svgs.vue'
import { useRouter } from 'vue-router'
import { inject } from 'vue'

const emitter = inject('emitter')
const router = useRouter()
const storePerfil = useStorePerfil()

function abrirModalAlterarSenha() {
  storePerfil.mostrarModalAlterarSenha = true
}

function abrirModalEditarPerfil() {
  storePerfil.mostrarModalEditarPerfil = true
}

function logout() {
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: "logout feito com sucesso" })
  localStorage.removeItem("unicpages-admin-access-token")
  router.push("/")
}
</script>

<style scoped>
section.criar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0 290px;
  min-height: 60dvh;
}

button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  background-color: var(--cor-cinza-1);
  border: 2px dashed var(--cor-cinza-3);
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

button.breve {
  pointer-events: none;
  opacity: 0.4;
}

button:hover {
  background-color: var(--cor-cinza-2);
}

button .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

button .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
}

button .texto p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
  transition: all 0.3s;
}

button:hover .icone {
  background-color: var(--cor-cinza-3);
}

button .icone svg {
  min-width: 20px;
  width: 20px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

button:hover .icone svg {
  fill: var(--cor-ciano);
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.criar {
    flex-direction: column;
    padding: 30px 20px 0 20px;
  }

  button {
    width: 100%;
    padding: 15px;
    margin: 0 0 20px 0;
    transition: all 0.3s;
  }

  button .icone {
    width: 45px;
    height: 45px;
  }

  button .icone img {
    max-width: 15px;
  }
}
</style>
