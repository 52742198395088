<template>
  <div id="sites">
    <SectionTopo/>
    <SectionLista/>
    <Footer/>
  </div>
</template>

<script setup>
import SectionLista from './partials/SectionLista.vue'
import SectionTopo from './partials/SectionTopo.vue'
import Footer from '../../global/footer/Footer.vue'

import { onMounted } from 'vue'
import { useStorePerfil } from '../../../stores/index.js'

const storePerfil = useStorePerfil()

function carregarPerfil () {
  storePerfil.receberPerfilAdministrador()
}

onMounted(() => {
  carregarPerfil()
})

</script>

<style scoped>
</style>

