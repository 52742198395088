<template>
  <section class="modal" :class="{ mostrar: mostrarModalAlterarSenha }">
    <div class="conteudo">
      <div class="titulo">
        <Svgs nome="editar" />
        <div class="texto">
          <h3>Alterar senha</h3>
          <p>Altere sua senha abaixo</p>
        </div>
        <button @click="fecharModal">
          <span class="um"></span>
          <span class="dois"></span>
        </button>
      </div>

      <div class="campos">
        <label>Senha atual</label>
        <input type="password" spellcheck="false" placeholder="senha#123" autocomplete="current-password" v-model="state.senhaAtual">
        <label>Nova senha</label>
        <input type="password" spellcheck="false" placeholder="senha#123" autocomplete="new-password" v-model="state.senhaNova">
      </div>

      <div class="botao">
        <button class="salvar" @click="salvar">
          Salvar nova senha
          <span></span>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, watch, inject } from 'vue'
import { storeToRefs } from 'pinia'
import { useStorePerfil } from '../../../../stores/index.js'
import Svgs from '../../../../components/global/svgs/Svgs.vue'

const emitter=  inject('emitter')
const storePerfil = useStorePerfil()
const { mostrarModalAlterarSenha } = storeToRefs(storePerfil)

const state = reactive({
  senhaAtual: '',
  senhaNova: ''
})

function fecharModal() {
  storePerfil.mostrarModalAlterarSenha = false
}

function validarDados() {
  let mensagem
  if (state.senhaAtual === state.senhaNova) {
    mensagem = 'A nova senha não pode ser igual à senha atual'
  } else if (state.senhaNova?.length > 0 && state.senhaNova?.length < 6) {
    mensagem = 'A senha precisa ter no mínimo 6 caracteres'
  } else {
    return true
  }

  emitter.emit("mostrarAlerta", { tipo: 'negativo', mensagem })
}

function limparInputs() {
  state.senhaAtual = ''
  state.senhaNova = ''
}

async function salvar() {
  if(!validarDados()) return

  const payloadSenha = {
    senhaAtual: state.senhaAtual,
    senhaNova: state.senhaNova
  }

  if(state.senhaAtual && state.senhaNova) await storePerfil.alterarSenha(payloadSenha)

  await receberPerfilAtualizado()
  limparInputs()
}

async function receberPerfilAtualizado() {
  storePerfil.mostrarModalAlterarSenha = false
  await storePerfil.receberPerfilAdministrador()
}

watch(mostrarModalAlterarSenha, () => {
  if(mostrarModalAlterarSenha?.value === true) document.body.classList.add('bloquear')
  else document.body.classList.remove('bloquear')
})
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: #00000071;
  padding: 30px;
  z-index: 11;
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  transition: all 0.3s;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.modal.fechar {
  opacity: 0;
  visibility: visible;
  pointer-events: none;
}

.conteudo {
  width: 100%;
  max-height: 100%;
  max-width: 500px;
  background-color: var(--cor-cinza-1);
  border-radius: 10px;
  overflow-y: scroll;
}

.conteudo::-webkit-scrollbar-track {
  background-color: transparent;
}

.conteudo .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-bottom: 2px solid var(--cor-cinza-3);
}

.conteudo .titulo svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  margin: 0 15px 0 0;
}

.conteudo .titulo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.conteudo .titulo .texto p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.conteudo .titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  margin-left: auto;
  transition: all 0.5s;
}

.conteudo .titulo button span {
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: var(--cor-vermelho);
  transition: all 0.5s;
}

.conteudo .titulo button span.um {
  transform: rotate(45deg);
}

.conteudo .titulo button span.dois {
  transform: rotate(-45deg);
}

.conteudo .titulo button:hover {
  transform: rotate(90deg)
}

.conteudo .titulo button:hover span {
  background-color: var(--cor-branco);
}

.campos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px 30px 0 30px;
}

.campos label {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 15px 0;
}

.campos input {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  margin: 0 0 20px 0;
  transition: all 0.3s;
}

.campos input:focus {
  background-color: var(--cor-cinza-3);
}

.campos textarea {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  border-radius: 10px;
  resize: none;
  width: 100%;
  transition: all 0.3s;
}

.campos textarea:focus {
  background-color: var(--cor-cinza-3);
}

.botao {
  width: 100%;
  padding: 10px 30px 30px 30px;
}

button.salvar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  background: linear-gradient(90deg, var(--degrade-verde));
  background-size: 100% 100%;
  width: 100%;
  padding: 25px 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

button.salvar:hover {
  background-size: 200% 200%;
}

button.salvar:disabled {
  color: transparent;
}

button.salvar:disabled span {
  opacity: 1;
  visibility: visible;
}

button.salvar span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco);
  border-top: 2px solid transparent;
  box-shadow: 0 5px 30px 0 #ffffff5a;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: girando 0.5s linear infinite;
  pointer-events: none;
  transition: all 0.3s;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.modal {
    align-items: flex-start;
    padding: 10px;
  }

  section.modal .titulo {
    padding: 20px;
  }

  section.modal .botao {
    padding: 20px;
  }

  section.modal .campos {
    padding: 20px 20px 0 20px;
  }
}
</style>
