<template>
  <section class="modal" :class="{ mostrar: modalUploadFotoAberto, cheio: (!cropAtivado && !imagem?.src) || imagem?.src }" ref="modal">
    <div class="conteudo" :class="{ cheio: (!cropAtivado && !imagem?.src) || imagem?.src }" ref="quadro">
      <div class="titulo">
        <Svgs nome="arquivo"/>
        <div class="texto">
          <h3>Subir imagem</h3>
          <p>Suba, edite e salve</p>
        </div>
        <button @click="fecharModal">
          <span class="um"></span>
          <span class="dois"></span>
        </button>
      </div>
      <div class="carregar" @click="carregarImagemDispositivo" v-if="!cropAtivado && !imagem?.src">
        <Svgs nome="subir"/>
        <h3>Selecionar arquivo</h3>
        <p>.png .jpeg .webp</p>
        <input id="uploadImagem" class="input" hidden :type="state.typeInput" ref="inputFile" @change="preencherStoreComImagem">
      </div>
      <div class="foto">
        <div class="tamanho">
          <Cropper class="previa"
          :stencil-component="stencilComponent"
          :src="imagem?.src"
          :allowStartNewCrop="false"
          ref="cropper"
          v-if="cropAtivado && imagem?.src"/>
          <div class="previa" :style="`background-image: url(${imagem?.src})`" v-if="!cropAtivado && imagem?.src"></div>
        </div>
        <div class="acoes">
          <div class="opcoes">
            <button type="button" @click="girarImagem" v-if="cropAtivado && imagem?.src">
              <Svgs nome="girar"/>
            </button>
            <button type="button" @click="zoomImagem(1.5)" v-if="cropAtivado && imagem?.src">
              <Svgs nome="zoom-in"/>
            </button>
            <button type="button" @click="zoomImagem(0.5)" v-if="cropAtivado && imagem?.src">
              <Svgs nome="zoom-out"/>
            </button>
          </div>
          <div class="opcoes transparente">
            <button type="button" class="vermelho" @click="limparInputs" v-if="cropAtivado && imagem?.src">
              <Svgs nome="x"/>
            </button>
            <button type="button" class="verde" @click="validarSalvamento" v-if="cropAtivado && imagem?.src">
              <Svgs nome="check"/>
            </button>
          </div>
        </div>
      </div>
      <div class="salvando" v-if="loading">
        <span></span>
        <p>Salvando imagem...</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, inject, onMounted, onUnmounted, reactive, ref, getCurrentInstance } from 'vue'
import { storeToRefs } from 'pinia'
import { useStoreCropImagem } from '../../../stores/index.js'
import { Cropper, CircleStencil, RectangleStencil } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"
import apiUploadImagem from '../../../api/upload/api-upload'
import Svgs from "../../../components/global/svgs/Svgs.vue"

const state = reactive({
  typeInput: 'file',
  dadosArquivo: {
    mimetype: '',
    name: '',
    size: 0
  },
  arquivo: '',
  arquivoBackup: '',
  mostrarInstrucoes: false
})

const cropper = ref(null)
const inputFile = ref(null)
const emitter = inject('emitter')
const storeCropImagem = useStoreCropImagem()
const currentInstance = getCurrentInstance()

const { cropConfig, modalUploadFotoAberto, loading, cropAtivado, imagem, callback } = storeToRefs(storeCropImagem)

const stencilComponent = computed(() => cropConfig?.value?.tipoRecorte === 'retangulo' ? RectangleStencil : CircleStencil )

function limparInputs() {
  revogarUrlBlob(storeCropImagem.imagem.src)
  resetarInputFile()
  storeCropImagem.loading = false
  storeCropImagem.cropAtivado = false
  storeCropImagem.imagem.src = ''
  storeCropImagem.imagem.type = ''
  storeCropImagem.imagemBackup.src = ''
  storeCropImagem.imagemBackup.type = ''
  storeCropImagem.cropConfig.tipoRecorte = 'retangulo'
  storeCropImagem.cropConfig.stencilProps.aspectRatio = 1.8

  state.dadosArquivo = { mimetype: '', name: '', size: 0 }
}

function fecharModal() {
  storeCropImagem.modalUploadFotoAberto = false
  limparInputs()
}

onMounted(() => {
  document.addEventListener('keydown', handleKeydown)
})

onUnmounted(() => {
  document.removeEventListener('keydown', handleKeydown)
})

function handleKeydown(event) {
  if (event.key === 'Escape') fecharModal()
}

function resetarInputFile() {
  state.typeInput = 'file'
}

function carregarImagemDispositivo() {
  inputFile.value.click()
}

function validarExtensoes(mimetype) {
  const extensoesAutorizados = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp']
  if (extensoesAutorizados.includes(mimetype)) return true

  const mensagem = 'A imagem deve estar em um dos seguintes formatos: .png, .jpg .jpeg .webp'
  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
  return false
}

function validarTamanho(size) {
  const MB20 = 20006563
  if (size <= MB20) return true

  const mensagem = 'A imagem deve ter no máximo 20MB.'
  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
  return false
}

function converterArquivoEmBlob(file) {
  return URL.createObjectURL(file)
}

function revogarUrlBlob(blob) {
  URL.revokeObjectURL(blob)
}

function converterBlobEmArquivo(blob, nome, mimetype) {
  return new File([blob], nome, { type: mimetype })
}

function preencherStoreComImagem() {
  const elemento = currentInstance?.proxy?.$el
  const input = elemento.querySelector(`input[type="file"]`)
  const file = input.files[0]
  if (!file) return

  const size = file.size
  const name = file.name
  const mimetype = file.type
  state.dadosArquivo = { mimetype, name, size }

  if (!validarExtensoes(mimetype)) return
  if (!validarTamanho(size)) return

  if (storeCropImagem.imagem.src) revogarUrlBlob(storeCropImagem.imagem.src)

  const blob = converterArquivoEmBlob(file)
  const reader = new FileReader()

  reader.onload = (e) => {
    storeCropImagem.cropAtivado = true

    const payload = { src: blob, type: mimetype }
    storeCropImagem.imagem = payload
    state.arquivoBackup = payload

    cropper.value?.refresh()
    setTimeout(() => imagemZoomMinimo(), 500)
  }
  reader.readAsArrayBuffer(file)
}

function girarImagem() {
  if (cropper.value) {
    const angulo = 90
    cropper.value.rotate(angulo)
  }
}

function zoomImagem(fator) {
  if (cropper.value) cropper.value.zoom(fator)
}

function imagemZoomMinimo() {
  zoomImagem(0.5)
  zoomImagem(0.5)
  zoomImagem(0.5)
}

function validarSalvamento() {
  if (imagem.value?.src) return cortarImagem()

  const mensagem = 'Selecione uma imagem para continuar'
  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
}

async function salvarImagem() {
  const tamanhoMaximo = 1920
  const formData = converterFormData(state.arquivo, tamanhoMaximo)

  try {
    storeCropImagem.loading = true
    const resp = await apiUploadImagem.uploadImagem(formData)
    const urlImagem = resp?.url
    enviarImagemParaComponenteExterno(urlImagem)
  }
  catch (error) {
    storeCropImagem.loading = false
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Ocorreu um erro' })
    limparInputs()
  }
}

async function enviarImagemParaComponenteExterno(urlImagem) {
  emitter.emit(callback.value, urlImagem)
  fecharModal()
}

function converterFormData (arquivo, maxWidth = false) {
  const formData = new window.FormData()
  formData.append('arquivo', arquivo)

  if (maxWidth) formData.append('maxWidth', maxWidth)
  return formData
}

function cortarImagem() {
  if (cropper.value) {
    const { canvas } = cropper.value.getResult()
    revogarUrlBlob(imagem.value?.src)
    canvas.toBlob((blob) => substituirImagemOriginal(blob), state.dadosArquivo.mimetype)
  }
}

function substituirImagemOriginal(blob) {
  const arquivo = converterBlobEmArquivo(blob, state.dadosArquivo.name, state.dadosArquivo.mimetype)
  state.arquivo = arquivo

  storeCropImagem.imagem.src = converterArquivoEmBlob(arquivo)
  storeCropImagem.cropAtivado = false
  setTimeout(() => salvarImagem(), 200)
}
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: #00000071;
  padding: 30px;
  z-index: 15;
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  transition: all 0.3s;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.modal.fechar {
  opacity: 0;
  visibility: visible;
  pointer-events: none;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 500px;
  background-color: var(--cor-cinza-1);
  border-radius: 10px;
  overflow-y: scroll;
}

.conteudo::-webkit-scrollbar-track {
  background-color: transparent;
}

.conteudo .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-bottom: 2px solid var(--cor-cinza-3);
}

.conteudo .titulo svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  margin: 0 15px 0 0;
}

.conteudo .titulo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.conteudo .titulo .texto p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.conteudo .titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  margin-left: auto;
  transition: all 0.5s;
}

.conteudo .titulo button span {
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: var(--cor-vermelho);
  transition: all 0.5s;
}

.conteudo .titulo button span.um {
  transform: rotate(45deg);
}

.conteudo .titulo button span.dois {
  transform: rotate(-45deg);
}

.conteudo .titulo button:hover {
  transform: rotate(90deg)
}

.conteudo .titulo button:hover span {
  background-color: var(--cor-branco);
}

/* Carregar */
.carregar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 170px;
  background-color: var(--cor-cinza-2);
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s;
}

.carregar:hover {
  background-color: var(--cor-cinza-3);
}

.carregar h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.carregar p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.carregar svg {
  width: 50px;
  min-width: 50px;
  fill: var(--cor-branco);
  margin: 0 0 20px 0;
}

.carregar input {
  display: none;
}

/* Carregar */
.salvando {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 140px;
  background-color: var(--cor-cinza-3);
  padding: 40px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 2;
}
.salvando p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-branco);
}
.salvando span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid var(--cor-branco);
  border-top: 5px solid transparent;
  margin: 0 0 20px 0;
  animation: rodandoObjeto 1s ease-in-out infinite;
}
@keyframes rodandoObjeto {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Extras */

.foto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  padding: 120px 40px 0 40px;
}

.foto .tamanho {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 100%;
}

.foto .tamanho .previa {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* ações */
.foto .acoes {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40px;
  width: 100%;
}

.foto .opcoes {
  display: flex;
  align-items: center;
}

.foto .opcoes button {
  position: relative;
  width: 60px;
  height: 60px;
  background-color: var(--cor-cinza-2);
  border-radius: 50%;
  margin: 0 5px;
  transition: all 0.3s;
}

.foto .opcoes button:hover {
  background-color: var(--cor-cinza-3);
}

.foto .opcoes button svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
  transition: all 0.2s;
}

.foto .opcoes button.vermelho {
  background-color: var(--cor-vermelho);
}

.foto .opcoes button.vermelho svg {
  fill: var(--cor-branco);
}

.foto .opcoes button.vermelho:hover {
  filter: brightness(110%);
}

.foto .opcoes button.verde {
  background-color: var(--cor-azul);
}

.foto .opcoes button.verde svg {
  fill: var(--cor-branco);
}

.foto .opcoes button.verde:hover {
  filter: brightness(110%);
}
@media screen and (max-width: 1000px) {
  section.modal {
    align-items: flex-start;
    padding: 10px;
  }
  .foto {
    padding: 110px 30px 0 30px;
  }
  .foto .acoes {
    top: 30px;
  }
}
</style>
