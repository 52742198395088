<template>
  <section class="modal" :class="{ mostrar: mostrarModalResetarSenha }">
    <div class="conteudo">

      <div class="titulo">
        <div class="foto" :style="`background-image: url('${storeUsuarios.modals.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
        <div class="texto">
          <h3>Deseja resetar para 123456789?</h3>
          <p>{{ storeUsuarios.modals.email }}</p>
        </div>
        <button @click="fecharModal">
          <span class="um"></span>
          <span class="dois"></span>
        </button>
      </div>

      <div class="campos">
        <label>Digite RESETAR abaixo para confirmar</label>
        <input type="text" placeholder="Digite aqui" v-model="state.confirmacao" @keydown.enter="resetarSenha">
      </div>

      <div class="botao">
        <button class="excluir" @click="resetarSenha">
          Resetar senha
          <span></span>
        </button>
      </div>

    </div>
  </section>
</template>

<script setup>
import { reactive, inject, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useStoreUsuarios } from '../../../../stores/index.js'
import Svgs from '../../../global/svgs/Svgs.vue'

const emitter = inject('emitter')
const storeUsuarios = useStoreUsuarios()
const { mostrarModalResetarSenha } = storeToRefs(storeUsuarios)

const state = reactive({
  confirmacao: ''
})

function fecharModal() {
  storeUsuarios.mostrarModalResetarSenha = false
  state.confirmacao = ''
}

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') fecharModal()
})

function resetarSenha () {
  if (state.confirmacao?.toLowerCase()?.trim() !== 'resetar') {
    const mensagem = 'É necessário digitar "resetar" para alterar a senha'
    emitter.emit("mostrarAlerta", { tipo: 'negativo', mensagem })
    return
  }

  const idUsuario = storeUsuarios.modals.idUsuario
  if (!idUsuario) return

  storeUsuarios.resetarSenhaUsuario(idUsuario)
  fecharModal()
}

</script>

<style scoped>
section.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: #00000071;
  padding: 30px;
  z-index: 11;
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  transition: all 0.3s;
}
section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
section.modal.fechar {
  opacity: 0;
  visibility: visible;
  pointer-events: none;
}
.conteudo {
  width: 100%;
  max-width: 550px;
  background-color: var(--cor-cinza-1);
  border-radius: 10px;
  overflow-y: scroll;
}
.conteudo::-webkit-scrollbar-track {
  background-color: transparent;
}
.conteudo .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-bottom: 2px solid var(--cor-cinza-3);
}
.conteudo .titulo .foto {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 0 15px 0 0;
}
.conteudo .titulo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}
.conteudo .titulo .texto p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}
.conteudo .titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  margin-left: auto;
  transition: all 0.5s;
}
.conteudo .titulo button span {
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: var(--cor-vermelho);
  transition: all 0.5s;
}
.conteudo .titulo button span.um {
  transform: rotate(45deg);
}
.conteudo .titulo button span.dois {
  transform: rotate(-45deg);
}
.conteudo .titulo button:hover {
  transform: rotate(90deg)
}
.conteudo .titulo button:hover span {
  background-color: var(--cor-branco);
}
.campos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px 30px 0 30px;
}
.campos label {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 15px 0;
}
.campos input {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  transition: all 0.3s;
}
.campos input:focus {
  background-color: var(--cor-cinza-3);
}
.botao {
  width: 100%;
  padding: 20px 30px 30px 30px;
}
button.excluir {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  background: linear-gradient(90deg, var(--degrade-vermelho));
  background-size: 100% 100%;
  width: 100%;
  padding: 25px 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}
button.excluir:hover {
  background-size: 200% 200%;
}
button.excluir:disabled {
  color: transparent;
}
button.excluir:disabled span {
  opacity: 1;
  visibility: visible;
}
button.excluir span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco);
  border-top: 2px solid transparent;
  box-shadow: 0 5px 30px 0 #ffffff5a;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: girando 0.5s linear infinite;
  pointer-events: none;
  transition: all 0.3s;
}
/* Responsivo */
@media screen and (max-width: 1000px) {
  section.modal {
    align-items: flex-start;
    padding: 10px;
  }
  section.modal .titulo {
    padding: 20px;
  }
  section.modal .botao {
    padding: 20px;
  }
  section.modal .campos {
    padding: 20px 20px 0 20px;
  }
}
</style>
