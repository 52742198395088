<template>
  <div id="sites">
    <ModalAcesso />
    <ModalResetarSenha />
    <ModalSites />
    <ModalBloquearAcesso />
    <SectionTopo />
    <SectionLista />
    <Footer />
  </div>
</template>

<script setup>
import ModalAcesso from './modals/ModalAcesso.vue'
import ModalResetarSenha from './modals/ModalResetarSenha.vue'
import SectionLista from './partials/SectionLista.vue'
import SectionTopo from './partials/SectionTopo.vue'
import Footer from '../../global/footer/Footer.vue'

import { onMounted } from 'vue'
import { useStorePerfil } from '../../../stores/index.js'
import ModalSites from './modals/ModalSites.vue'
import ModalBloquearAcesso from './modals/ModalBloquearAcesso.vue'

const storePerfil = useStorePerfil()

function carregarPerfil() {
  storePerfil.receberPerfilAdministrador()
}

onMounted(() => {
  carregarPerfil()
})
</script>

<style scoped></style>
