<template>
  <section class="topo">
    <div class="plano">
      <img src="https://arquivos.unicpages.com/imagens/app/planos/admin.png"/>
      <h3>{{ storeUsuarios?.usuarios?.length }} usuários Unic</h3>
    </div>
    <a @click="router.push('/perfil')" class="perfil">
      <div class="foto"
        :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg' }')`">
      </div>
      <div class="texto">
        <h3>Seu perfil</h3>
        <p>{{ storePerfil.usuario?.nome }}</p>
      </div>
    </a>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStorePerfil } from '../../../../stores/index.js'
import { useStoreUsuarios } from '../../../../stores/index.js'

const storeUsuarios = useStoreUsuarios()

const router = useRouter()
const storePerfil = useStorePerfil()
</script>

<style scoped>
section.topo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 0 290px;
}

.plano {
  display: flex;
  align-items: center;
  width: 75%;
  background-color: var(--cor-cinza-2);
  border: 2px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px;
}
.plano img {
  width: 100%;
  max-width: 40px;
}
.plano h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-transform: capitalize;
  margin: 0 0 0 15px;
}
.perfil {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-cinza-2);
  border: 2px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px;
  width: 25%;
  margin: 0 0 0 20px;
  transition: all 0.3s;
}

.perfil:hover {
  background-color: var(--cor-cinza-1);
  border: 2px solid var(--cor-cinza-3);
}

.perfil .foto {
  width: 35px;
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.perfil .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 0 0 0 15px;
}

.perfil .texto h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
}

.perfil .texto p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.topo {
    flex-direction: column;
    padding: 100px 20px 0 20px;
  }

  .plano {
    width: 100%;
    padding: 15px;
    margin: 0 0 20px 0;
  }

  .perfil .foto {
    width: 30px;
    height: 30px;
    min-width: 30px;
  }

  .perfil {
    display: none;
  }
}
</style>
