<template>
  <section class="erro">
    <Particles />
    <div class="conteudo">
      <div class="linha"></div>
      <div class="titulo">
        <img src="https://arquivos.unicpages.com/imagens/app/logotipos/unicpages.svg" />
        <p>Essa tela não existe... Tente novamente e caso o erro persista chame o time Unic</p>
      </div>
      <a class="voltar" @click="router.push('/sites')">Voltar ao Início</a>
      <div class="opcoes">
        <a href="https://wa.me/551831993340">Precisa de ajuda?</a>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import Particles from '../../../global/particles/Particles.vue'

const router = useRouter()
</script>

<style scoped>
section.erro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: #0e0505;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 100dvh;
  background: linear-gradient(90deg, #0c0c0e8e, #0c0c0e, #0c0c0e8e);
  backdrop-filter: blur(45px);
  -webkit-backdrop-filter: blur(45px);
}

.conteudo .linha {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-position: center;
  background: radial-gradient(circle, var(--degrade));
  background: -webkit-radial-gradient(circle, var(--degrade));
  animation: degradeHorizontal 30s linear infinite;
}

.conteudo .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudo .titulo img {
  width: 100%;
  max-width: 150px;
  margin: 0 0 15px 0;
}

.conteudo .titulo p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.conteudo a.voltar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  background: linear-gradient(90deg, var(--degrade-verde));
  background-size: 100% 100%;
  width: 100%;
  max-width: 400px;
  padding: 25px 20px;
  border-radius: 10px;
  margin: 20px 0 0 0;
  position: relative;
  transition: all 0.3s;
}

.conteudo a.voltar:hover {
  background-size: 200% 200%;
}

.conteudo .opcoes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  border-top: 2px solid var(--cor-cinza-3);
}

.conteudo .opcoes a {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: transparent;
  transition: all 0.3s;
}

.conteudo .opcoes a:hover {
  color: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.erro {
    display: flex;
    flex-direction: column-reverse;
  }

  .conteudo {
    width: 100%;
    padding: 50px 30px;
    border-radius: 0 0 30px 30px;
  }
}
</style>
