<template>
  <section class="lista">
    <div class="input">
      <Svgs class="icone" nome="lupa" />
      <input type="text" spellcheck="false" placeholder="Buscar usuário" v-model="termoBusca" @input="filtrarUsuarios" />
    </div>
    <div class="item" v-for="usuario in usuariosFiltrados" :key="usuario._id">
      <div class="info">
        <div class="foto" :style="`background-image: url('${usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
        <div class="nome">
          <h3>{{ usuario?.nome }}</h3>
          <p>{{ usuario?.email }}</p>
          <h4>
            <img :src="`https://arquivos.unicpages.com/imagens/app/planos/${usuario?.assinatura?.tipo}.png`" />
            Plano {{ usuario?.assinatura?.tipo }} {{ usuario?.assinatura?.plano }}
          </h4>
        </div>
      </div>
      <div class="botoes">
        <button class="estado" :class="{ ativo: usuario?.assinatura?.ativo }">
          <span></span>
          <p>{{ usuario?.assinatura?.ativo ? 'Ativo' : 'Bloqueado' }}</p>
        </button>
        <button class="sites" @click="abrirSites(usuario)">
          <Svgs nome="cursor" />
          <p>Sites</p>
        </button>
        <button class="acesso" @click="abrirModalAcesso(usuario)">
          <Svgs nome="estrela" />
          <p>Acesso</p>
        </button>
        <button class="bloquear" @click="abrirBloquearAcesso(usuario)">
          <Svgs nome="x" />
          <p>Bloquear</p>
        </button>
        <button class="senha" @click="abrirResetarSenha(usuario)">
          <Svgs nome="cadeado" />
          <p>Senha</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { watchEffect, onMounted, ref } from 'vue'
import { useStoreUsuarios, useStorePages } from '@stores'
import Svgs from '@svgs'

const storeUsuarios = useStoreUsuarios()
const storePages = useStorePages()
const termoBusca = ref('')
const usuariosFiltrados = ref([])

function abrirSites(usuario) {
  enviarDadosProsModals(usuario)

  const idUsuario = storeUsuarios.modals.idUsuario
  storeUsuarios.pagesUsuario = []
  storePages.receberPorUsuario(idUsuario)

  storeUsuarios.mostrarModalSites = true
}

function abrirModalAcesso(usuario) {
  enviarDadosProsModals(usuario)
  storeUsuarios.mostrarModalAcesso = true
}

function abrirBloquearAcesso(usuario) {
  enviarDadosProsModals(usuario)
  storeUsuarios.mostrarModalBloquearAcesso = true
}

function abrirResetarSenha(usuario) {
  enviarDadosProsModals(usuario)
  storeUsuarios.mostrarModalResetarSenha = true
}

function enviarDadosProsModals(usuario) {
  storeUsuarios.modals.idUsuario = usuario._id
  storeUsuarios.modals.email = usuario.email
  storeUsuarios.modals.imagemPerfil = usuario.imagemPerfil
}

function filtrarUsuarios() {
  const termo = termoBusca.value.trim().toLowerCase()
  if (termo === '') {
    usuariosFiltrados.value = storeUsuarios.usuarios
  } else {
    usuariosFiltrados.value = storeUsuarios.usuarios.filter((usuario) => {
      return usuario.nome.toLowerCase().includes(termo) || usuario.email.toLowerCase().includes(termo) || usuario.assinatura.plano.toLowerCase().includes(termo) || usuario.assinatura.tipo.toLowerCase().includes(termo)
    })
  }
}

function carregarUsuarios() {
  storeUsuarios.receberTodos()
}

watchEffect(() => {
  usuariosFiltrados.value = storeUsuarios.usuarios
})

onMounted(() => {
  carregarUsuarios()
})
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px 0 290px;
  min-height: 75dvh;
}
.input {
  margin: 0 0 20px 0;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px 10px 20px 20px;
  margin: 0 0 20px 0;
  transition: all 0.3s;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.item .foto {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.item .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5px;
  min-width: 5px;
  height: 70px;
  min-height: 70px;
  border-radius: 10px;
  background-color: var(--cor-branco);
  transition: all 0.3s;
}

.item .icone.free {
  background: linear-gradient(90deg, var(--degrade-plano-free));
  background: -webkit-linear-gradient(90deg, var(--degrade-plano-free));
}

.item .icone.silver {
  background: linear-gradient(90deg, var(--degrade-plano-silver));
  background: -webkit-linear-gradient(90deg, var(--degrade-plano-silver));
}

.item .icone.gold {
  background: linear-gradient(90deg, var(--degrade-plano-gold));
  background: -webkit-linear-gradient(90deg, var(--degrade-plano-gold));
}

.item .icone.diamond {
  background: linear-gradient(90deg, var(--degrade-plano-diamond));
  background: -webkit-linear-gradient(90deg, var(--degrade-plano-diamond));
}

.item .icone.unlimited {
  background: linear-gradient(90deg, var(--degrade-plano-unlimited));
  background: -webkit-linear-gradient(90deg, var(--degrade-plano-unlimited));
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 20px;
}

.item .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item .nome h4 {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  text-transform: capitalize;
}
.item .nome h4 img {
  width: 10px;
  margin: 0 5px 0 0;
}

.item .nome p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0;
}

.item .botoes {
  display: flex;
  align-items: center;
}
.item .botoes button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  border-left: 2px solid var(--cor-cinza-3);
  width: 90px;
  height: 90px;
}
.item .botoes button:hover p {
  color: var(--cor-branco);
}
.item .botoes button:hover svg {
  fill: var(--cor-branco);
}
.item .botoes button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 12px 0 0 0;
  transition: all 0.3s;
}
.item .botoes button.estado {
  flex-direction: row;
  border-left: none;
  width: auto;
  pointer-events: none;
}
.item .botoes button.estado.ativo p {
  color: var(--cor-verde);
}
.item .botoes button.estado p {
  color: var(--cor-vermelho);
  margin: 0 20px 0 5px;
}
.item .botoes button span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--cor-vermelho);
  box-shadow: 0 10px 30px 0 var(--cor-vermelho);
}
.item .botoes button.ativo span {
  background-color: var(--cor-verde);
  box-shadow: 0 10px 30px 0 var(--cor-verde);
}
.item .botoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}
/* Responsivo */
@media screen and (max-width: 1000px) {
  section.lista {
    padding: 0 20px 0 20px;
  }
  .item {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    margin: 0 0 20px 0;
  }
  .item .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 20px 0 0;
  }
  .item .foto {
    width: 50px;
    height: 50px;
  }
  .item .nome {
    margin-right: 0;
    padding: 15px 0 10px 0;
  }
  .item .nome .dominio {
    flex-direction: column;
    align-items: flex-start;
  }
  .item .status {
    padding: 0;
  }
  .item .botoes {
    flex-direction: column;
  }
  .item .botoes button {
    flex-direction: row;
    justify-content: flex-start;
    border-left: none;
    border-top: 2px solid var(--cor-cinza-3);
    width: 90px;
    height: 40px;
    padding: 0 0 0 10px;
    transition: all 0.3s;
  }
  .item .botoes button.estado {
    flex-direction: row;
    border-top: none;
    border-left: none;
    border-bottom: 2px solid var(--cor-cinza-3);
    width: 90px;
    pointer-events: none;
  }
  .item .botoes button.estado span {
    margin: 0 5px 0 0;
  }
  .item .botoes button.ativo {
    border-top: auto;
  }
  .item .botoes button.sites {
    border-top: none;
  }
  .item .botoes button p {
    margin: 0 0 0 5px;
  }
  .item .botoes button svg {
    width: 11px;
    min-width: 11px;
  }
}
</style>
