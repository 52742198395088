<template>
  <div id="sites">
    <SectionTopo />
    <SectionDados />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStorePerfil } from '../../../stores/index.js'
import SectionTopo from './partials/SectionTopo.vue'
import Footer from '../../global/footer/Footer.vue'
import SectionDados from './partials/SectionDados.vue'

const storePerfil = useStorePerfil()

function carregarPerfil() {
  storePerfil.receberPerfilAdministrador()
}

onMounted(() => {
  carregarPerfil()
})
</script>

<style scoped></style>
